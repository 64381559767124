<template>
  <a-modal v-model="addPlaceVisible" :title="this.names" @onCancel="handleCancel" @ok="handleSubmit">
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
      <a-form-model-item has-feedback label="合同编号" prop="contractNo">
        <a-input v-model.trim="query.contractNo" autocomplete="off" placeholder="请输入合同编号" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="合同状态" prop="contractSts">
        <a-select v-model="query.contractSts" placeholder="请选择合同状态">
          <a-select-option value="0"> 未归档 </a-select-option>
          <a-select-option value="1"> 归档 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="合同期限" prop="times">
        <a-range-picker @change="onChange" v-model="query.times" format="YYYY-MM-DD">
          <a-icon slot="suffixIcon" type="smile" />
        </a-range-picker>
      </a-form-model-item>

      <a-form-model-item has-feedback label="客户经理" prop="businessUser">
        <a-select v-model="query.businessUser" placeholder="请输入客户经理">
          <a-select-option v-for="item in this.businessUser" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="合同类型" prop="contractType">
        <a-select v-model="query.contractType" placeholder="请选择合同类型" @change="onContract">
          <a-select-option value="1"> 媒体和代理 </a-select-option>
          <a-select-option value="2"> 媒体和广告主 </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item has-feedback label="甲方公司" prop="contractFirstParty">
        <a-select v-model="query.contractFirstParty" placeholder="请选择甲方公司" v-if="this.valPush == 1">
          <a-select-option v-for="item in agentUser" :key="item.id" :value="item.id">
            {{ item.agentName }}
          </a-select-option>
        </a-select>
        <a-select v-model="query.contractFirstParty" placeholder="请选择甲方公司" v-if="this.valPush == 2">
          <a-select-option v-for="item in industry" :key="item.id" :value="item.id">
            {{ item.agentName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item has-feedback label="乙方公司" prop="contractSecondParty">
        <a-input v-model.trim="query.contractSecondParty" autocomplete="off" placeholder="请输入乙方公司" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="收入模式" prop="incomeType">
        <a-select v-model="query.incomeType" placeholder="请选择收入模式">
          <a-select-option value="1"> 直客收入 </a-select-option>
          <a-select-option value="2"> 平台收入 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="收款方式" prop="collectionType">
        <a-select v-model="query.collectionType" placeholder="请选择收款方式">
          <a-select-option value="1"> 预收 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="合同附件" prop="iconUrl">
        <!-- action="uploadUrl" -->
        <!-- :customRequest="selfUpload" -->
        <a-upload
          :action="uploadUrl"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          @change="handleChange"
          :headers="header"
          :multiple="true"
        >
          <div v-for="item in this.imagesPush" :key="item">
            <img v-if="item" :src="resourceUrl + item" alt="合同附件" style="width: 120px" />
          </div>
          <!-- <img v-if="query.iconUrl" :src="resourceUrl + query.iconUrl" alt="图标" style="width: 120px" />
 -->
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  agentList, // 代理
  advertiserList, // 广告主
  contractAdd,
  contractUpdate,
  businessUserList
} from '@/api/financialManagementA'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      valPush: '',
      imagesPush: [],
      contractTypes: '',
      agentUser: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {
        appId: [{ required: true, message: '请选择应用', trigger: 'change' }],
        contractNo: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
        contractSts: [{ required: true, message: '请选择合同状态', trigger: 'change' }],
        times: [{ required: true, message: '请选择合同期限', trigger: 'change' }],
        businessUser: [{ required: true, message: '请输入客户经理', trigger: 'blur' }],
        contractType: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
        contractFirstParty: [{ required: true, message: '请选择甲方公司', trigger: 'change' }],
        contractSecondParty: [{ required: true, message: '请输入乙方公司', trigger: 'blur' }],
        incomeType: [{ required: true, message: '请选择收入模式', trigger: 'change' }],
        collectionType: [{ required: true, message: '请选择收款方式', trigger: 'change' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        if (this.isAdd === 1) {
          this.names = '新增合同'
        } else if (this.isAdd === 2) {
          this.names = '修改合同'
          this.query = JSON.parse(JSON.stringify(val))
          this.query.contractType = val.collectionType
          this.valPush = val.collectionType * 1
          this.query.contractFirstParty = val.contractFirstParty * 1
          this.query.businessUser = val.businessUser * 1
          const imes = []
          imes.push(val.contractStartTime)
          imes.push(val.contractEndTime)
          this.query.times = imes
          this.imagesPush = val.attachmentUrls.split(',')
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getAdvertiserList()
    this.getAgentList()
    this.getBusinessUserList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    onChange (date, dateString) {
      this.query.times = dateString
      this.query.contractStartTime = this.query.times[0]
      this.query.contractEndTime = this.query.times[1]
    },
    onContract (val) {
      this.valPush = val
    },
    // 广告主
    async getAdvertiserList () {
      const res = await advertiserList()
      if (res.code === 200) {
        this.industry = res.data
      }
    },
    // 代理
    async getAgentList () {
      const res = await agentList()
      if (res.code === 200) {
        this.agentUser = res.data
      }
    },
    // 经理
    async getBusinessUserList () {
      const res = await businessUserList()
      if (res.code === 200) {
        this.businessUser = res.data
      }
    },
    changeUser (val) {},
    changeTradeId (val) {
      this.query.advertiserIndustry = val[1] * 1
      if (val.length > 0) {
        this.query.tradePid = val[0]
        this.query.tradeId = val[1]
      }
    },
    handleCancel () {
      this.isAdd = ''
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.isAdd === 1) {
              const resp = await contractAdd(this.query)
              if (resp.code === 200) {
                this.$message.success('添加成功!')
                this.$emit('changeAppInfo', 'add')
              }
            } else if (this.isAdd === 2) {
              const resp = await contractUpdate(this.query)
              if (resp.code === 200) {
                this.$message.success('修改成功!')
                this.$emit('changeAppInfo', 'add')
              }
            }
            this.query = {}
            this.$emit('changePlace')
            this.addPlaceVisible = false
          } else {
            return false
          }
        }
      })
    },

    // selfUpload(file){
    // 	this.datts = file.file
    // },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.imagesPush.push(info.file.response.data)
        // this.query.attachmentUrls = []
        this.query.attachmentUrls = this.imagesPush.join(',')
        this.loading = false
      }
    },
    beforeUpload (file) {
      this.datts = file
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件！')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('请上传小于2M的图片!')
      }
      return isJpgOrPng && isLt2M
    }
  }
}
</script>

<style></style>
