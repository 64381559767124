<template>
  <div class="sonfig-container">
    <div class="sonfig_right">
      <div class="adplace_left_search">
        <div>
          <span class="sonfig_date">选中日期</span>
          <a-range-picker @change="onChange" v-model="dateString" format="YYYY-MM-DD">
            <a-icon slot="suffixIcon" type="smile" />
          </a-range-picker>
          <a-select
            style="width: 120px; margin-left: 10px"
            @change="changeTradeId"
            v-model="contractSts"
            :allowClear="false"
            class="sonfig_businessUser"
            placeholder="请选择合同状态"
          >
            <a-select-option v-for="item in contractStsPush" :key="item.table">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-input
            v-model.trim="contractNo"
            autocomplete="off"
            style="width: 120px; margin-left: 10px"
            placeholder="请输入合同编号"
          />
          <a-select
            style="width: 120px; margin-left: 10px"
            @change="handleProvinceChange"
            v-model="contractType"
            :allowClear="false"
            class="sonfig_businessUser"
            placeholder="请选择合同类型"
          >
            <a-select-option v-for="item in contractTypePush" :key="item.table">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-button type="primary" @click="handleSearch">搜索</a-button>
        </div>
        <div>
          <a-spin :spinning="loading">
            <a-table
              class="m_table_cust"
              :pagination="false"
              :columns="columns"
              :data-source="dataList"
              :rowKey="
                (record, index) => {
                  return index
                }
              "
              bordered
            >
              <div slot="accounts">直客</div>
              <template slot="action" slot-scope="text, record">
                <a-button size="small" @click="handleClickView(record)">查看</a-button>
                <!-- <a-button size="small" @click="handleClickUpdatePlace(record)">修改</a-button> -->
              </template>
            </a-table>
            <a-pagination
              class="pagination"
              v-if="dataList.length > 0"
              :show-total="(total) => `共 ${total} 条`"
              show-size-changer
              :current="query.page"
              :total="total"
              @change="handleChangePage"
              @showSizeChange="onShowSizeChange"
            />
          </a-spin>
        </div>
      </div>
    </div>

    <!-- <PlacesModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="this.advertisers"
      @changePlace="changePlace"
    /> -->
    <Views
      v-if="addPlaceView"
      :placeView="placeView"
      :visible="addPlaceView"
      :isAdd="this.adverView"
      @modalCancel="addPlaceView = false"
      @changePlace="changePlace"
    />
  </div>
</template>

<script>
import { contractListPage } from '@/api/financialManagementB'
import PlacesModal from '../components/placesModal'
import Views from '../components/view'
import { mapGetters } from 'vuex'
// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'addTime',
    title: '日期',
    key: 'addTime',
    align: 'left'
  },
  {
    dataIndex: 'contractNo',
    title: '合同编号',
    key: 'contractNo',
    align: 'center',
    scopedSlots: { customRender: 'contractNo' }
  },
  {
    dataIndex: 'contractSts',
    title: '合同状态',
    key: 'contractSts',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.contractSts === '0') {
        text = '未归档'
      } else if (record.contractSts === '1') {
        text = '归档'
      }
      return text
    }
  },
  {
    dataIndex: 'deadline',
    title: '合同期限',
    key: 'deadline',
    align: 'center',
    customRender: (text, record, index) => {
      text = record.contractStartTime + '---' + record.contractEndTime
      return text
    }
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    align: 'center'
  },
  {
    dataIndex: 'companyName',
    title: '甲方公司',
    key: 'companyName',
    align: 'center'
  },
  {
    dataIndex: 'contractSecondParty',
    title: '乙方公司',
    key: 'contractSecondParty',
    align: 'center'
  },
  {
    dataIndex: 'incomeType',
    title: '收入模式',
    key: 'incomeType',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.incomeType === '1') {
        text = '直客收入'
      } else if (record.incomeType === '2') {
        text = '平台收入'
      }
      return text
    }
  },
  {
    dataIndex: 'collectionType',
    title: '收款方式',
    key: 'collectionType',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.collectionType === '1') {
        text = '预收'
      }
      return text
    }
  },

  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  }
]
export default {
  name: 'Contractb',
  components: { PlacesModal, Views },
  data () {
    return {
      contractType: undefined,
      contractSts: undefined,
      contractStsPush: [
        {
          value: '未归档',
          table: 0
        },
        {
          value: '归档',
          table: 1
        }
      ],
      contractTypePush: [
        {
          value: '媒体和代理',
          table: 1
        },
        {
          value: '媒体和广告主',
          table: 2
        }
      ],
      contractNo: '',
      advertisers: '',
      adverView: '',
      columns,
      addPlaceVisible: false,
      addPlaceView: false,
      placeInfo: {},
      supplier: 1,
      placeView: {},
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      mode: 'inline',
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      keys: '',
      types: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted () {
    this.getContractListPage()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    onChange (date, dateString) {
      this.dateStrings = dateString
      this.times = this.dateStrings
    },
    handleClick (e) {
      this.keys = e.key
      this.getContractListPage()
    },
    changeMode (checked) {
      this.mode = checked ? 'vertical' : 'inline'
    },
    changeTheme (checked) {
      this.theme = checked ? 'dark' : 'light'
    },

    async getContractListPage () {
      this.loading = true
      this.dataList = []
      const res = await contractListPage(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },

    // 新增广告主
    // handleClickAdd (type) {
    //   this.advertisers = 1
    //   this.addPlaceVisible = true
    // },
    handleSearch () {
      //    this.dateString = []
      //    this.times = []
      //    this.contractSts = undefined
      // this.contractNo = ''
      // this.contractType = undefined
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        contractSts: this.contractSts,
        contractNo: this.contractNo || '',
        contractType: this.contractType || ''
      }
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    },
    changeTradeId (val) {},
    changeBusiness (val) {},
    handleProvinceChange (value) {
      this.manager = value
      this.businessUserPush = this.manager
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        advertiserIndustry: this.advertiserIndustry || '',
        businessUser: this.businessUserPush || ''
      }
    },

    changePlace () {
      if (this.keys === '1' || this.keys === '') {
        this.getContractListPage()
      } else if (this.keys === '2') {
        this.getAgentListPage()
      }
    },
    // 查看
    handleClickView (record) {
      this.placeView = record
      this.addPlaceView = true
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.sonfig-container {
  padding: 0 10px;
  margin-top: 10px;
  .sonfig_left {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    min-height: 620px;
  }
  .ant-menu-item-selected {
    background-color: fade(@primary-color,  5%);
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      border-radius: 5px;
      border-left: 3px solid @primary-color;
      border-right: none;
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .adplace_left_search {
    height: 50px;
    padding: 0 10px 10px 10px;
    padding: 8px;
    position: relative;
    height: 100%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sonfig_date {
    margin-left: 10%;
    margin-right: 10px;
  }
  .sonfig_industry {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sonfig_businessUser {
    margin-right: 10px;
  }
  .ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 10px;
  }
  .sonfig_right {
    flex-grow: 1;
  }
}
</style>
